<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Tutor Groups</h1>
        </div>
      </div>
    </div>
    <div
      v-for="(group, index) in tutorGroups"
      v-bind:key="index"
      class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h4>Tutor Group: {{ group.name }}</h4>
          <ul>
            <router-link
              tag="li"
              class="link"
              v-for="(user, uindex) in group.users"
              v-bind:key="uindex"
              v-bind:to="`/view-user/${user.id}`">
              {{ user.first_name }} {{ user.last_name }}
              <span v-if="user._joinData.member_type === 2"> | Tutor</span>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ViewTutorGroups',
  data() {
    return {
      tutorGroups: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getTutorGroups() {
      axios.get(`/tutorGroups/get.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.tutorGroups = response.data.tutorGroups;
        });
    },
  },
  mounted() {
    this.getTutorGroups();
  },
};
</script>
